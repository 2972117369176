<template>
  <div class="distribution">
    <!-- 送货人 -->
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-cascader ref="cascader" v-model="search" :options="options" placeholder="请选择省市" :props="{ value: 'code', label: 'name', checkStrictly: true }" @change="onSearch" clearable></el-cascader>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="provinceName" label="省"></el-table-column>
          <el-table-column prop="cityName" label="市"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="100">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" size="" plain @click="handleShow(scope.row)">查看商超</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      search: "",
      regionCode: "",
      tableData: [],
      options: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getList()
  },
  mounted() {
    this.getAreas()
  },
  methods: {
    // 省市区
    getAreas() {
      this.$axios.get(this.$api.getSecondDistrictInfo).then(res => {
        if (res.data.code == 100) {
          this.options = res.data.result
        }
      })
    },
    // 列表
    getList() {
      this.loading = true
      this.$axios
        .get(this.$api.selectSupplierDistrictList, {
          params: {
            supplierId: this.$store.state.userInfo.supplierId,
            regionCode: this.regionCode,
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          this.tableData = res.data.result.list
          this.totalItemsCount = res.data.result.totalCount
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 搜索
    onSearch(e) {
      this.regionCode = e[e.length - 1]
      this.tableData = []
      this.currentPage = 1
      this.getList()
    },
    handleShow(row) {
      this.$router.push({ path: "/business/detail/shopList", query: { provinceCode: row.provinceCode, cityCode: row.cityCode } })
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.getList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getList()
    },
  },
}
</script>

<style scoped lang="scss">
.distribution {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;

      /deep/ .el-input__inner {
        border-radius: 10px;
      }
    }

    .el-button {
      margin-left: 20px;
    }
  }

  .disabled_user_account {
    width: 255px;
    color: #c0c4cc;
    padding: 0 15px;
    border-radius: 10px;
    background-color: #f5f7fa;
    box-sizing: border-box;
    cursor: not-allowed;
  }
}
</style>
